import HTTP from '@/http-common.js'

export default {
    async list(page) {
        try {
            const { data } = await HTTP.get(`/services/?page=${page}`);
            return data
        } catch (error) {
            const { data } = error.response;
            return data
        }
    },
    async search(keyword) {
        try {
            const { data } = await HTTP.get(`/services/search/code/?guia=${keyword}`);
            return data
        } catch (error) {
            const { data } = error.response;
            return data
        }
    }
}