<template>
  <div>
    <div id="div-with-loading-services" class="vs-con-loading__container">
      <ag-grid-table
        :animateRows="true"
        :suppressRowClickSelection="true"
        :columnDefs="columnDefs"
        :rowData="currentServices"
        :pagination="false"
        :gridOptions="gridOptions"
        :sizeTofit="true"
        :searchExternal="true"
        :showPaginationTop="false"
        rowSelection="single"
        @RefreshTableData="RefreshTableData"
      >
        <template v-slot:searchInput>
          <vs-input
            class="mb-4 mr-1 FatherClass md:mb-0"
            :placeholder="$t('search')"
            v-model="searchQuery"
            :disabled="!advanceSearch"
            @keyup.enter="newAdvanceSearch"
          ></vs-input>
          <vs-button
            radius
            class="mx-2"
            type="flat"
            icon-pack="feather"
            icon="icon-search"
            :disabled="!advanceSearch"
            @click="newAdvanceSearch"
            size="small"
          ></vs-button>
          <vs-switch v-model="advanceSearch">
            <template #off> {{ $t("default_search") }} </template>
            <template #on> {{ $t("advance_search") }}</template>
          </vs-switch>
        </template>
      </ag-grid-table>
      <vs-pagination
        v-show="showPages"
        class="mt-6"
        :total="totalPages"
        v-model="currentPage"
      ></vs-pagination>
    </div>
  </div>
</template>

<script>
import FieldsServices from "../../fields/services/fieldsServices";
import { translateTableFields } from "@/filter";
import AgGridTable from "@/components/tableAgGrid/Index2";
import ActionsBar from "@/components/tableAgGrid/ActionsBar";
import ServicesApi from "../../api/services";
import moment from "moment";
const COMPONENT_NAME = "ServicesIndex";
export default {
  name: COMPONENT_NAME,
  data() {
    return {
      pages: [],
      currentPage: 1,
      totalPages: 1,
      gridOptions: {
        localeText: { noRowsToShow: `${this.$t("show_not_data")}` },
      },
      columnDefs: translateTableFields(FieldsServices.columnDefs),
      services: [],
      currentServices: [],
      searchServices: [],
      loading: false,
      searchQuery: "",
      advanceSearch: false,
      showPages: true,
    };
  },
  components: {
    "ag-grid-table": AgGridTable,
    "ag-action-bar": ActionsBar,
  },
  computed: {},
  watch: {
    async currentPage(val) {
      this.searchQuery = "";
      let aux = this.pages.find((item) => item === val);
      if (!aux) await this.listServices(val);
      this.currentServices = this.services.filter((item) => item.page === val);
    },
    advanceSearch(val) {
      if (!val) {
        this.showPages = true;
        this.currentServices = this.services.filter(
          (item) => item.page === this.currentPage
        );
      } else {
        if (this.searchQuery !== "") this.currentServices = this.searchServices;
      }
    },
  },
  methods: {
    RefreshTableData() {
      this.columnDefs = [];
      setTimeout(() => {
        this.columnDefs = translateTableFields(FieldsServices.columnDefs);
      }, 50);
    },
    updateSearchQuery() {
      if (this.searchQuery !== "") {
        let query = this.searchQuery.toLowerCase();
        this.currentServices = this.services.filter(
          (item) =>
            item.collective.toLowerCase().includes(query) ||
            item.note.toLowerCase().includes(query)
        );
      } else {
        this.currentServices = this.services.filter(
          (item) => item.page === this.currentPage
        );
      }
    },
    async listServices(page = 1) {
      this.loading = true;
      this.$vs.loading({
        container: "#div-with-loading-services",
        type: "material",
        scale: 1,
      });
      try {
        let aux = await ServicesApi.list(page);
        if (aux.message.total_pages) {
          this.totalPages = aux.message.total_pages;
        }
        aux = aux.message.services.map((item) => ({
          ...item,
          created_at: moment(item.created_at).format("DD/MM/YYYY"),
          updated_at: moment(item.updated_at).format("DD/MM/YYYY"),
          page,
        }));
        Array.prototype.push.apply(aux, this.services);
        this.services = aux;
        this.pages.push(page);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.$vs.loading.close("#div-with-loading-services > .con-vs-loading");
      }
    },
    async newAdvanceSearch() {
      this.loading = true;
      this.$vs.loading({
        container: "#div-with-loading-services",
        type: "material",
        scale: 1,
      });
      try {
        let aux = await ServicesApi.search(this.searchQuery);
        this.currentServices = aux.data.map((item) => ({
          ...item,
          created_at: moment(item.created_at).format("DD/MM/YYYY"),
          updated_at: moment(item.updated_at).format("DD/MM/YYYY"),
        }));
        this.searchServices = this.currentServices;
      } catch (error) {
        this.currentServices = [];
        this.$vs.notify({
          text: this.$t("notify.try_again"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } finally {
        this.showPages = false;
        this.loading = false;
        this.$vs.loading.close("#div-with-loading-services > .con-vs-loading");
      }
    },
  },
  async mounted() {
    await this.listServices(1);
    this.currentServices = this.services;
  },
};
</script>

<style lang="scss" scoped>
</style>
