<template>
  <services-index />
</template>

<script>
import ServicesIndex from "@/components/services/Index.vue";
import { validatePermission } from "@/Ability";

const COMPONENT_NAME = "ViewIndexService";

export default {
  name: COMPONENT_NAME,
  components: {
    "services-index": ServicesIndex,
  },
  created() {
   validatePermission(this, "read", "services", "Home");
  },
};
</script>