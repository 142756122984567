<template>
  <vx-card id="card-new" style="position: relative">
    <div class="pt-3 ag-grid-table-actions" v-if="filterOptions">
      <slot name="filterOptions"></slot>
      <vs-divider></vs-divider>
    </div>
    <div
      id="header-table"
      class="flex flex-wrap justify-between items-center"
      v-show="showHeader"
    >
      <div class="mt-2 mb-4 mr-1 ag-grid-table-actions-left md:mb-0">
        <PaginationTopShow
          v-if="showPaginationTopTable"
          @update-page-show="updatePageShow"
        ></PaginationTopShow>
      </div>
      <div class="mt-2 mb-4 mr-1 ag-grid-table-actions-left md:mb-0">
        <PaginationTop
          v-if="showPaginationTop"
          :pagesInfo="pagesInfo"
          :pageSizeOptions="pageSizeOptions"
          @update-page-size="updatePageSize"
        ></PaginationTop>
        <ShowColumn
          v-if="showColumn"
          :columnData="getStateData"
          @assign-column-data-show="assignColumnDataShow"
          class="mx-1"
          :class="{ 'ml-12': !showPaginationTop }"
        ></ShowColumn>
        <PinnedColumn
          v-if="showPinnedColumn"
          ref="pinnedColumn"
          :columnData="getStateData"
          @assign-column-data-pin="assignColumnDataPin"
          class="mx-1"
        ></PinnedColumn>
        <SizeFields
          v-if="showAutoSizeAll"
          @auto-size-all="autoSizeAll"
          class="mx-1"
        ></SizeFields>
      </div>
      <div class="ag-grid-table-actions" v-if="centerOptions">
        <slot name="centerOptions"></slot>
      </div>
      <slot name="actionBar"></slot>
      <div
        class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        v-if="isSearch"
      >
        <slot v-if="searchExternal" name="searchInput"></slot>
        <vs-input
          v-else
          class="mb-4 mr-1 FatherClass md:mb-0"
          icon="search"
          :placeholder="$t('search')"
          v-model="searchQuery"
          @input="updateSearchQuery"
        ></vs-input>
      </div>
    </div>
    <ag-grid-vue
      :style="styleProp"
      :class="classProp"
      :rowAnimation="true"
      :columnDefs="columnData"
      :defaultColDef="defaultColDef"
      :colResizeDefault="colResizeDefault"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :pagination="pagination"
      :paginationPageSize="paginationPageSize"
      :paginationAutoPageSize="paginationAutoPageSize"
      :animateRows="animateRows"
      :suppressRowClickSelection="suppressRowClickSelection"
      :suppressClickEdit="suppressClickEdit"
      :floatingFilter="floatingFilter"
      :suppressPaginationPanel="true"
      :headerHeight="40"
      :getRowHeight="getRowHeight"
      :rowSelection="rowSelection"
      :rowMultiSelectWithClick="true"
      :context="context"
      :frameworkComponents="frameworkComponents"
      :singleClickEdit="singleClickEdit"
      @first-data-rendered="onFirstDataRenderedTable"
      @grid-columns-changed="gridColumnsChanged"
      @row-data-changed="rowDataChangedTable"
      @filter-changed="filterChanged"
      @cell-clicked="cellClicked"
      @row-selected="rowSelected"
      @selection-changed="selectionChanged"
      @cell-editing-stopped="cellEditingStopped"
    ></ag-grid-vue>
    <vs-pagination
      v-if="pagination"
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    ></vs-pagination>
    <p class="text-site">
      {{ selectedServicesInfo }}
    </p>
  </vx-card>
</template>
<script>
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import FieldsAgGridTable from "@/fields/ag-grid/fieldsAgGridTable";
import PaginationTopShow from "@/components/tableAgGrid/PaginationTopShow";
import PaginationTop from "@/components/tableAgGrid/PaginationTop";
import SizeFields from "@/components/tableAgGrid/SizeFields";
import ShowColumn from "@/components/tableAgGrid/ShowColumn";
import PinnedColumn from "@/components/tableAgGrid/PinnedColumn";


const NAME = "AgGridTable";

export default {
  name: NAME,
  components: {
    AgGridVue,
    PaginationTop,
    SizeFields,
    ShowColumn,
    PinnedColumn,
    PaginationTopShow,
  },
  props: {
    styleProp: {
      type: String,
      required: false,
      default: "width: 100%; height:50vh",
    },
    classProp: {
      type: String,
      required: false,
      default: "ag-theme-material w-100 my-4 ag-grid-table",
    },
    rowSelection: {
      type: String,
      required: false,
      default: "multiple",
    },
    columnDefs: {
      type: Array,
      required: true,
    },
    rowData: {
      type: Array,
      required: true,
    },
    gridOptions: {
      type: Object,
      required: false,
    },
    selectedServicesInfo: {
      type: String,
      required: false,
    },
    pagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    searchExternal: {
      type: Boolean,
      required: false,
      default: false,
    },
    paginationAutoPageSize: {
      type: Boolean,
      required: false,
      default: false,
    },
    animateRows: {
      type: Boolean,
      required: false,
      default: false,
    },
    sizeTofit: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    suppressRowClickSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    cellClicked: {
      type: Function,
      required: false,
      default: () => {},
    },
    rowSelected: {
      type: Function,
      required: false,
      default: () => {},
    },
    selectionChanged: {
      type: Function,
      required: false,
      default: () => {},
    },
    onFirstDataRendered: {
      type: Function,
      required: false,
      default: () => {},
    },
    colResizeDefault: {
      type: String,
      required: false,
      default: "shift",
    },
    floatingFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    rowDataChanged: {
      type: Function,
      required: false,
      default: () => {},
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    centerOptions: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    filterOptions: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    showPaginationTop: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    showPaginationTopTable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    showColumn: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    showPinnedColumn: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    showAutoSizeAll: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    cellEditingStopped: {
      type: Function,
      required: false,
      default: () => {},
    },
    suppressClickEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    singleClickEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      pinned: null,
      context: null,
      frameworkComponents: null,
      searchQuery: "",
      columnData: [],
      pagesInfo: "",
      pageSizeOptions: [20, 50, 100, 200, 500],
      totalRowsDisplayed: 0,
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        suppressMenu: true,
      },
      settings: {
        maxScrollbarLength: 5,
        wheelSpeed: 0.6,
      },
      paginationPageSize: 10,
      maxPageNumbers: 7,
      totalPages: 0,
      currentPage: 1,
      getStateData: null,
    };
  },
  beforeMount() {
    this.frameworkComponents = {    };
  },
  created() {
    this.context = { componentParent: this };
    //this.gridOptions.localeText = this.assignLocalText();
  },
  mounted() {
    this.columnData = this.columnDefs;
    Vue.nextTick(() => this.gridOptions.api.sizeColumnsToFit());
    this.assignColumnData(this.columnData);
  },
  watch: {
    columnDefs(val) {
      this.assignColumnData(val);
      this.getPaginationData();
    },
    "$i18n.locale"() {
      this.assignColumnData(this.columnDefs);
      //this.gridOptions.localeText = this.assignLocalText();
      this.gridOptions.api.showNoRowsOverlay();
      this.getPagesInfo();
      this.$emit("refresh-table-data");
    },
    currentPage(val) {
      this.gridOptions.api.paginationGoToPage(val - 1);
      this.getPagesInfo();
    },
  },
  methods: {
    updatePageShow(val) {
      this.$emit("update-page-show-table", val);
    },
    getRowHeight() {
      return 40;
    },
    assignColumnDataPin(keyShow, hide) {
      this.gridOptions.columnApi.setColumnPinned(keyShow, hide);
      this.gridOptions.api.sizeColumnsToFit();
      let allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    },
    assignColumnDataShow(keyShow, hide) {
      this.gridOptions.columnApi.setColumnVisible(keyShow, hide);
      this.gridOptions.api.sizeColumnsToFit();
      let allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    },
    assignColumnData(data) {
      this.columnData = data.map((column) => {
        let translate = "";
        if (column.noLanguage) {
          translate = column.traslateName[this.$i18n.locale];
        }
        if (typeof column.traslateName === "object") {
          translate = column.traslateName[this.$i18n.locale];
        } else {
          translate = `${this.$t(column.traslateName)}`;
        }
        return {
          ...column,
          headerName: translate,
        };
      });
    },
    assignLocalText() {
      let translatedText = {};
      for (const key in FieldsAgGridTable) {
        translatedText[key] = this.$t(FieldsAgGridTable[key]);
      }
      return translatedText;
    },
    updateSearchQuery(val) {
      this.gridOptions.api.setQuickFilter(val);
    },
    getPaginationData() {
      this.paginationPageSize = this.gridOptions.api.paginationGetPageSize();
      this.totalPages = this.gridOptions.api.paginationGetTotalPages();
      this.currentPage = this.gridOptions.api.paginationGetCurrentPage() + 1;
    },
    getStateDataColumn() {
      this.getStateData = this.gridOptions.columnApi.getColumnState();
      this.getStateData = this.getStateData.map((column) => {
        let indexFound = this.columnData.find(
          (columnIndex) => columnIndex.field == column.colId
        );
        if (indexFound) {
          return {
            ...column,
            hide: (column.hide = !column.hide),
            traslateName: indexFound,
          };
        }
        return {
          ...column,
          hide: (column.hide = !column.hide),
        };
      });
    },
    gridColumnsChanged() {
      if (this.sizeTofit) {
        this.gridOptions.api.sizeColumnsToFit();
      }
      this.getStateDataColumn();
    },
    getPagesInfo() {
      this.totalRowsDisplayed = this.gridOptions.api.getDisplayedRowCount();
      this.pagesInfo = `${
        this.currentPage * this.paginationPageSize -
        (this.paginationPageSize - 1)
      } -
        ${
          this.totalRowsDisplayed - this.currentPage * this.paginationPageSize >
          0
            ? this.currentPage * this.paginationPageSize
            : this.totalRowsDisplayed
        }
        ${this.$t("of")} ${this.totalRowsDisplayed}`;
    },
    updatePageSize(val) {
      this.gridOptions.api.paginationSetPageSize(val);
      this.paginationPageSize = val;
      this.totalPages = this.gridOptions.api.paginationGetTotalPages();
      this.currentPage = this.gridOptions.api.paginationGetCurrentPage() + 1;
      this.getPagesInfo();
    },
    rowDataChangedTable(params) {
      this.totalPages = this.gridOptions.api.paginationGetTotalPages();
      this.getPagesInfo();
      this.rowDataChanged(params);
    },
    filterChanged() {
      this.getPaginationData();
      this.getPagesInfo();
    },
    autoSizeAll() {
      this.setSize = !this.setSize;
      if (this.setSize) {
        let allColumnIds = [];
        this.gridOptions.columnApi.getAllColumns().forEach((column) => {
          allColumnIds.push(column.colId);
        });
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
      } else {
        this.gridOptions.api.sizeColumnsToFit();
      }
    },
    onFirstDataRenderedTable(params) {
      params.api.sizeColumnsToFit();
      params.api.setFloatingFiltersHeight(40);
      this.onFirstDataRendered(params);
    },
  },
};
</script>
<style scoped>
.text-site {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 3em;
  margin-right: 2em;
  font-size: 12px;
}

#header-table .vs-con-input-label {
  margin: 0 !important;
}

.ag-cell {
  line-height: 40px !important;
}

.ag-cell-label-container {
  height: 100%;
}

.ag-floating-filter-body {
  height: 80%;
}

.ag-grid-table .ag-filter-filter {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* .ag-header-cell {
  padding-left: 5px !important;
  padding-right: 2px !important;
} */

.ag-floating-filter-button {
  margin-left: 1% !important;
}

.ag-grid-table-actions {
  flex: 1;
  height: 100%;
}
#header-table
  > div.flex.flex-wrap.items-center.justify-between.ag-grid-table-actions-right
  > div
  > div
  > input {
  border-radius: 9999px !important;
}
#card-new > div.vx-card__collapsible-content.vs-con-loading__container > div {
  padding: 0rem 1rem 2rem 1rem;
}
.vs-pagination--li.is-current {
  color: black;
}
</style>
