import i18n from "@/i18n.js"

const columnDefs = [{
        headerName: `${i18n.t('column.id')}`,
        traslateName: "column.id",
        field: "id",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true
        },
        width: 15,
    },
    {
        headerName: `${i18n.t('column.code')}`,
        traslateName: "column.code",
        field: "code",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true
        },
        width: 25,
    },
    {
        headerName: `${i18n.t('column.guia')}`,
        traslateName: "column.guia",
        field: "guia",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true
        },
        width: 25,
    },
    {
        headerName: `${i18n.t('column.consecutivo')}`,
        traslateName: "column.consecutivo",
        field: "consecutivo",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true
        },
        width: 25,
    },
    {
        headerName: `${i18n.t('column.company')}`,
        traslateName: "column.company",
        field: "company",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true
        },
        width: 25,
    },
    {
        headerName: `${i18n.t('column.updated_at')}`,
        traslateName: "column.updated_at",
        field: "updated_at",
        sortable: true,
        filter: "agDateColumnFilter",
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            comparator: (filterLocalDateAtMidnight, // eslint-disable-line
                cellValue) => { // eslint-disable-line
                let dateAsString = cellValue;
                if (dateAsString == null) return -1;
                let dateParts = dateAsString.split("/");
                let cellDate = new Date(
                    Number(dateParts[2]),
                    Number(dateParts[1]) - 1,
                    Number(dateParts[0])
                );
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                }
                if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                }
                if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                }
            },
            browserDatePicker: true,
        },
        width: 30,
    },
    {
        headerName: `${i18n.t('column.created_at')}`,
        traslateName: "column.created_at",
        field: "created_at",
        sortable: true,
        filter: "agDateColumnFilter",
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            comparator: (filterLocalDateAtMidnight, // eslint-disable-line
                cellValue) => { // eslint-disable-line
                let dateAsString = cellValue;
                if (dateAsString == null) return -1;
                let dateParts = dateAsString.split("/");
                let cellDate = new Date(
                    Number(dateParts[2]),
                    Number(dateParts[1]) - 1,
                    Number(dateParts[0])
                );
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                }
                if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                }
                if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                }
            },
            browserDatePicker: true,
        },
        width: 30,
    }
]


export default {
    columnDefs
}